import { Amplify } from "aws-amplify";
import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { getAuth } from "./components/utility/Map";
import { NotificationProvider } from "./context/Notifications";
import "./index.css";

import App from "./App";
import RouteGuard from "./components/RouteGuard";
import { AuthProvider } from "./contexts/Auth";
import { MapProvider } from "./contexts/Map";
import ModalDemo from "./pages/ModalDemo";
import PinMap from "./pages/PinMap";
import SaveData from "./pages/SaveData";
import SignIn from "./pages/SignIn";

dotenv.config();

Amplify.configure({
  Auth: getAuth(),
});

ReactDOM.render(
  <React.StrictMode>
    <NotificationProvider>
      <MapProvider>
        <Router>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route
              path={"/map"}
              element={
                <AuthProvider>
                  <RouteGuard>
                    <App />
                  </RouteGuard>
                </AuthProvider>
              }
            />
            <Route
              path={"/modal"}
              element={
                <AuthProvider>
                  <RouteGuard>
                    <ModalDemo />
                  </RouteGuard>
                </AuthProvider>
              }
            />
            <Route
              path={"/saved-map"}
              element={
                <AuthProvider>
                  <RouteGuard>
                    <SaveData />
                  </RouteGuard>
                </AuthProvider>
              }
            />
            <Route
              path={"/pin"}
              element={
                <AuthProvider>
                  <RouteGuard>
                    <PinMap />
                  </RouteGuard>
                </AuthProvider>
              }
            />
          </Routes>
        </Router>
      </MapProvider>
    </NotificationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
