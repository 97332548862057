import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { AlertType, useNotification } from "../context/Notifications";
// import {  useNavigate  } from "react-router-dom";

const SIGNIN_WALLPAPER = process.env.REACT_APP_SIGNIN_WALLPAPER
  ? process.env.REACT_APP_SIGNIN_WALLPAPER
  : "/signinpic.jpg";
const THEME_COLOR = process.env.REACT_APP_THEME_COLOR
  ? process.env.REACT_APP_THEME_COLOR
  : "#1976d2";
const DEFAULT_TO_MAP = process.env.REACT_APP_DEFAULT_MAP ? true : false;

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        GeoMap
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    secondary: {
      main: `${THEME_COLOR}`,
    },
  },
});

export default function SignIn() {
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const [loading, setIsLoading] = React.useState(false);
  const login = async (username: string, password: string) => {
    try {
      const user = await Auth.signIn(username, password);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(user, password);
      }
      navigate(!DEFAULT_TO_MAP ? "/map" : "pin");
      return user;
    } catch (error) {
      showAlert(AlertType.Error, "Failed to logged in");
    }
    setIsLoading(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data: any = new FormData(event.currentTarget);
    // Sign Out the user out of any existing session

    await Auth.signOut();

    await login(
      data?.get("email").trim() || "",
      data?.get("password").trim() || ""
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Loading open={loading} />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${SIGNIN_WALLPAPER})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Copyright sx={{ mt: 5 }} />
              <Box paddingTop="10em">
                <p style={{ color: "#3B81F6" }}>Powered by</p>
                <a href="https://www.explore.ai/" target="_blank">
                  <img
                    src="https://uploads-ssl.webflow.com/62de8b6b5cbf78a4f4c6a8ca/63047dc78cdb0f42a8861715_ExploreAI_Horizontal_Dark-p-500.png"
                    alt="ExploreAI logo"
                  ></img>
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
